
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import { lang } from '@/lang'
import DomainItem from '@/views/SubDomain/components/SubDomainItem.vue'
import DeleteSubDomain from '@/views/SubDomain/dialog/DeleteSubDomain.vue'
import { deleteDomain } from '@/sdk/domain'
import { errorMessage, successMessage } from '@/utils/message'

@Component({
  components: {
    DomainItem,
    DeleteSubDomain,
    NoData: () => import('../components/NoData.vue')
  }
})
export default class SubdomainListAllPanel extends Vue {
  @Prop({ type: Array, default: () => [] })
  private domainInfoList!: Common.Domain.BaseInfoBackend[]

  private get text () {
    return {
      header_1: lang('DOMAIN_NAME_DOMAINS'),
      header_2: lang('DOMAIN_NAME_OWNER'),
      loading: lang('DOMAIN_NAME_LOADING')
    }
  }

  private handleClick (domain: string) {
    this.$router.push(`/details/${domain}`).catch(e => console.log('[router]:' + e.message))
  }

  @Ref() private deleteDialog!: DeleteSubDomain

  commandHandler (item: Common.Domain.BaseInfoBackend): void {
    this.deleteDialog.open(item)
  }

  async handlerDeleteSubDomain (domainItem: Common.Domain.BaseInfoBackend, unloading: () => unknown, done: () => unknown): Promise<void> {
    if (!domainItem.name) {
      unloading()
      done()
      return
    }

    await deleteDomain(domainItem.name)
      .then(() => {
        successMessage(lang('MESSAGE_DELETE_SUBDOMAIN_SUCCESS'))
        this.$emit('updateList')
        done()
      })
      .catch(() => {
        errorMessage(lang('MESSAGE_DELETE_SUBDOMAIN_ERR'))
      })
      .finally(() => unloading())
  }
}
