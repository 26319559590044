
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { shortAddr } from '@/utils/content'
import { lang } from '@/lang'

@Component({})
export default class SubDomainItem extends Vue {
  @Prop({ type: Object, required: true })
  private item!: Common.Domain.BaseInfoBackend

  @Prop({ type: Boolean, default: false })
  private editable!: boolean

  private checked = false

  private get verifyBtnText () {
    return lang('VALID_BTN')
  }

  private get showVerifyButton () {
    // return this.$route.query.userid && this.$route.query.chat
    return false
  }

  public setSelect (status: boolean) {
    this.checked = status
  }

  private goToDetail (domain: string) {
    this.$router.push({ name: 'details', params: { domain } }).catch(e => console.log('[router]:' + e.message))
  }

  @Watch('checked')
  statusChange (newVal: boolean): void {
    this.$emit('selectedStatus', newVal)
  }

  private shortAddr (address: string) {
    return shortAddr(address, 15)
  }

  private gotoVerify () {
    // /details/zfd.zzzzzzzzzzzzzzzzzzzzz.dot?tab=EDIT_RECORD
    this.$router.push(`/details/${this.item.name}?tab=EDIT_RECORD&userid=${this.$route.query.userid}&chat=${this.$route.query.chat}`)
  }
}
