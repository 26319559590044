
import { Vue, Component, Watch, Ref } from 'vue-property-decorator'
import { lang } from '@/lang'
import { ensureSetup, getAllDomains } from '@/sdk/domain'
import { wallet } from '@/utils/wallet'
import ReverRecordDialog from '../components/ReverRecordDialog.vue'
import ReverseRecord from '../components/ReverseRecord.vue'
import SubdomainListAllPanel from '../components/SubdomainListAllPanel.vue'
import { EventBus } from '@/utils/event'

@Component({
  components: {
    ReverseRecord,
    SubdomainListAllPanel,
    ReverRecordDialog
  }
})
export default class MySubdomain extends Vue {
  private loading = false

  private domainInfoList: Common.Domain.BaseInfoBackend[] = []

  private subDomainInfoList: Common.Domain.BaseInfoBackend[] = []

  @Ref() private dialog!: ReverRecordDialog

  private showDialog (currRecord: string) {
    this.dialog.open(currRecord)
  }

  private get text () {
    return {
      title: lang('DOMAIN_NAME_DETAILS_SUBDOMAIN')
    }
  }

  private async getDomainList () {
    if (wallet.accountId === '') {
      await wallet.connect()
    }

    try {
      this.loading = true
      const list = await getAllDomains()
      if (list) {
        this.domainInfoList = list
        this.subDomainInfoList = list.filter(item => {
          return item.parent !== '0x3fce7d1364a893e213bc4212792b517ffc88f5b13b86c8ef9c8d390c3a1370ce'
        })
        this.loading = false
      }
    } catch (e) {
      console.error('[app-getAllDomains]' + e.message)
      this.loading = false
    }
  }

  private get isConnect (): boolean {
    return wallet.accountId !== ''
  }

  private get accountId (): string {
    return wallet.accountId
  }

  mounted (): void {
    if (this.accountId !== '') {
      this.getDomainList()
    }

    this.$eventBus.on(EventBus.Types.WALLET_CONNECT_SUCCEED, this.getDomainList)
    // this.$eventBus.on(EventBus.Types.DOMAIN_BASEINFO_REFETCH, this.getDomainList)

    this.$once('hook:beforeDestroy', () => {
      this.$eventBus.off(EventBus.Types.WALLET_CONNECT_SUCCEED, this.getDomainList)
      this.$eventBus.off(EventBus.Types.DOMAIN_BASEINFO_REFETCH, this.getDomainList)
    })
    ensureSetup(true)
  }

  @Watch('isConnect')
  private connectChange (connect: boolean) {
    if (connect) {
      this.getDomainList()
    } else {
      this.domainInfoList = []
    }
  }

  @Watch('accountId')
  private accountIdChange (id: string, oldId: string) {
    if (id === '') return

    if (!id) return

    if (id) {
      this.getDomainList()
    } else {
      this.domainInfoList = []
    }
  }
}
