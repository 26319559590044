
import { lang } from '@/lang'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class DeleteSubDomain extends Vue {
  @Prop(Function)
  private confirmCallback!: (
    value: Common.Domain.BaseInfoBackend | undefined,
    unloading: () => void,
    done: () => void,
  ) => unknown

  private get text () {
    return {
      title: lang('SUBDOMAIN_DIALOG_DELETE_TITLE'),
      label: lang('SUBDOMAIN_DIALOG_DELETE_LABEL'),
      placeholder: lang('SUBDOMAIN_DIALOG_PLACEHOLDER'),
      buttonConfirm: lang('SUBDOMAIN_DIALOG_BUTTON_DELETE'),
      buttonCancel: lang('SUBDOMAIN_DIALOG_BUTTON_CANCEL')
    }
  }

  private domainItem: Common.Domain.BaseInfoBackend = {
    id: '',
    name: '',
    owner: '',
    parent: ''
  }

  private visible = false
  private loading = false

  open (domainItem: Common.Domain.BaseInfoBackend): void {
    this.domainItem = domainItem
    this.visible = true
  }

  private get disabled () {
    return !this.domainItem
  }

  private async handlerConfirm () {
    if (this.disabled) return
    if (!this.confirmCallback) return
    const unloading = () => (this.loading = false)
    const done = () => (this.visible = false)

    this.loading = true
    this.confirmCallback(this.domainItem, unloading, done)
  }
}
